<template>
  <div class="dash-table">
    <h2 class="title">
      {{ title }}
    </h2>
    <not-available
      v-if="error || results.length === 0"
      :type="error ? 'error': 'empty'"
      :height="335"
    />
    <transition
      v-if="!error && results && results.length > 0"
      name="fade"
      mode="out-in"
    >
      <loading-table
        v-if="loading"
        :labels="labels"
      />
      <table
        v-else
        class="table"
      >
        <thead class="head">
          <th
            class="head-item"
            v-for="(label) in labels"
            :key="label.column"
            @click="changeSortKey(label.column)"
          >
            <k-icon
              v-if="sortBy === label.column"
              icon="arrow-down1"
              class="icon"
            />
            {{ label.name }}
          </th>
        </thead>
        <tbody
          class="body"
        >
          <tr
            class="row"
            v-for="(result, index) in sortedResults"
            :class="isRowSelected(result) ? '-selected' : ''"
            :key="`dash-table-${index}`"
            @click="selectRow(result)"
          >
            <td
              v-for="(label) in labels"
              :key="`${label.column}-${index}`"
              class="column"
            >
              {{ adjustText(result[label.column]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </transition>
    <fake-pagination
      v-if="!error && results && results.length > 0"
      :current-page="currentPage"
      :last-page="numberPages"
      :total="results.length"
      @next="nextPage"
      @previous="previousPage"
      @last-page="lastPage"
      @first-page="firstPage"
    />
  </div>
</template>

<script>
import NotAvailable from '@/components/Dashboard/base/notAvailable.vue'
import { KIcon } from 'kaledo-components'
import FakePagination from '@/components/Dashboard/base/FakePagination.vue'
import LoadingTable from '@/components/Dashboard/loading/LoadingTable.vue'

export default {
  name: 'DashTable',
  components: {
    NotAvailable,
    KIcon,
    FakePagination,
    LoadingTable
  },
  props: {
    title: {
      type: String,
      default: 'Principais Destaques'
    },
    error: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableSelection: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: () => [
        {
          name: 'Nome Oferta',
          column: 'offer'
        },
        {
          name: 'Acessos',
          column: 'numberAccess'
        },
        {
          name: 'Resgates',
          column: 'redeem'
        }
      ]
    },
    results: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      sortBy: 'users',
      resultsCopy: [],
      sortedResults: [],
      numberPages: 1,
      currentPage: 1,
      selectedRow: null
    }
  },
  watch: {
    results () {
      this.resultsCopy = [...this.results]
      this.sortResults(this.sortBy)
    }
  },
  mounted () {
    this.sortedResults = [...this.results]
    this.sortResults(this.sortBy)
    this.createPagination()
  },
  methods: {
    adjustText (value) {
      if (typeof value !== 'string') return value
      else if (value.length < 17) return value
      return `${value.substring(0, 17)}...`
    },
    sortResults (key) {
      const results = [...this.results]
      const sorted = results.sort((a, b) => (a[key] < b[key]) ? 1 : -1)
      this.resultsCopy = sorted
      this.firstPage()
    },
    changeSortKey (key) {
      this.sortBy = key
      this.sortResults(key)
    },
    createPagination () {
      this.resultsCopy = [...this.results]
      if (this.results.length > 5) {
        this.numberPages = Math.ceil((this.results.length / 5))
        this.sortedResults = this.results.slice(0, 5)
      } else {
        this.sortedResults = this.results.slice(0, this.results.length)
      }
    },
    nextPage () {
      const init = this.currentPage * 5
      const endPage = (init + 5)
      const end = endPage > this.resultsCopy.length ? this.resultsCopy.length : endPage
      this.sortedResults = this.resultsCopy.slice(init, end)
      this.currentPage = this.currentPage + 1
    },
    previousPage () {
      this.currentPage = this.currentPage - 1
      const init = (this.currentPage * 5) - 5
      const end = (init + 5)
      this.sortedResults = this.resultsCopy.slice(init, end)
    },
    lastPage () {
      this.currentPage = this.numberPages
      const end = this.resultsCopy.length
      const init = (this.currentPage * 5) - 5
      this.currentPage = this.numberPages
      this.sortedResults = this.resultsCopy.slice(init, end)
    },
    firstPage () {
      this.currentPage = 1
      this.sortedResults = this.resultsCopy.slice(0, 5)
    },
    selectRow (row) {
      if (this.disableSelection) return false
      this.selectedRow = this.isRowSelected(row) ? null : row
      this.$emit('select-row', this.selectedRow)
    },
    isRowSelected (result) {
      return this.selectedRow && this.selectedRow.id === result.id
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/reference.scss';

.dash-table {
  width: 304px;
  min-height: 408px;
  background: white;
  padding: 30px 20px 20px 20px;
  position: relative;
  @include mobile {
    width: 100%;
  }
  .empty {
    margin-top: -20px;
  }
  .loading {
    text-align: center;
  }
  .title, .table > .head > .head-item {
    color: #5E6684;
    text-transform: uppercase;
    font-family: $base-font-family;
  }
  .title {
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
    color: $text-color;
  }
  .table {
    margin-bottom: 48px;
    .head {
      border-bottom: 1px solid $another-color;
    }
    .loading-page {
      max-width: 100%;
    }
    .head-item {
      font-size: 12px;
       font-weight: 400;
      padding-bottom: 10px;
      padding-right: 8px;
      text-align: center;
      vertical-align: bottom;
      cursor: pointer;
      &:first-child {
        width: 100%;
        text-align: left;
      }
      &:last-child {
        padding-right: 0px;
      }
      .icon {
        color: #00B2E3;
        font-size: 20px;
        display: block;
        margin: 0 auto;
      }
    }
    .row {
      font-size: 12px;
      color: $title-color;
      transition: all ease 0.3s;
      &:hover {
        background: #D8E0F6;
      }
      &:focus {
        background: #202950;
        color: white;
      }
      .column {
        padding: 14px 8px 10px 8px;
        text-align: right;
        &:first-child {
          text-align: left;
        }
      }
      .first, .last {
        padding: 16px 0;
      }
      .last {
        text-align: right;
        font-weight: 600;
      }
    }
    .-selected {
      background: #202950;
      color: white;
    }
    .row + .row {
      border-top: 1px solid $another-color;
    }
  }
}
</style>
