<template>
  <div>
    <div class="title-container">
      <h3 class="title">
        {{ table.title }}
      </h3>
      <info-button
        icon="info-information-circle"
        :info="infos[table.prop] || null"
      />
    </div>
    <div class="main-table">
      <not-available
        class="not-available"
        v-if="!loading && results.length === 0 || !success"
        :key="loading"
        :type="success ? 'empty': 'error'"
        :height="335"
      />
      <transition
        v-else
        name="fade"
        mode="out-in"
      >
        <table
          class="table"
        >
          <thead class="head">
            <th
              class="head-item"
              v-for="(label) in tableData.labels[table.prop]"
              :key="label.column"
              @click="changeSortKey(label.column)"
            >
              <k-icon
                v-if="sortBy === label.column"
                icon="arrow-down1"
                class="icon"
              />
              {{ label.name }}
            </th>
          </thead>
          <tbody
            v-if="loading"
            class="body"
          >
            <tr
              class="row"
              v-for="(number, index) in 5"
              :key="`dash-table-${index}`"
            >
              <td
                v-for="(label) in tableData.labels[table.prop]"
                :key="`${label.column}-${index}`"
                class="column"
              >
                <loading-bar />
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
            class="body"
          >
            <tr
              class="row"
              v-for="(result, index) in sortedResults"
              :key="`dash-table-${index}`"
            >
              <td
                v-for="(label) in tableData.labels[table.prop]"
                :key="`${label.column}-${index}`"
                class="column"
              >
                <div
                  v-if="table.prop === 'notifications' && label.column === 'name'"
                >
                  <span class="link">{{ result.name }}</span><br />
                  <span>{{ result.message }}</span>
                </div>

                <div
                  v-else-if="table.prop === 'notifications' && label.column === 'opens'"
                  style="text-align: center"
                >
                  <span>{{ result[label.column] }}</span>
                </div>
                <div
                  v-else-if="label.isImage"
                  class="image-container"
                >
                  <img
                    :src="result[label.column]"
                    class="image"
                  />

                  <button
                    class="overlay-button"
                    @click="isOpened = true, selectedImage = result[label.column]"
                  >
                    visualizar
                  </button>

                  <c-modal
                    class="banner-preview"
                    name="banner-preview"
                    :is-opened="isOpened"
                    @close="isOpened = false"
                  >
                    <h2>Preview</h2>
                    <img
                      :src="selectedImage"
                    />
                  </c-modal>
                </div>

                <span v-else>{{ result[label.column] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </transition>
      <div
        class="fake-pagination"
        v-if="results.length > 0"
      >
        <fake-pagination
          :current-page="currentPage"
          :last-page="numberPages"
          @next="nextPage"
          @previous="previousPage"
          @last-page="lastPage"
          @first-page="firstPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CModal from '@/components/CComponents/CModal.vue'
import NotAvailable from '../base/notAvailable.vue'
import LoadingBar from '../loading/LoadingBar.vue'
import FakePagination from '../base/FakePagination.vue'
import InfoButton from '../base/InfoButton.vue'

export default {
  name: 'CommunicationTable',
  components: {
    NotAvailable,
    LoadingBar,
    CModal,
    FakePagination,
    InfoButton

  },
  props: {
    success: Boolean,
    tableData: Object,
    selectedfilters: Array,
    table: {
      type: Object

    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      tables: [
        {
          title: 'Banners Home',
          prop: 'banners'
        },
        {
          title: 'Notificações',
          prop: 'notifications'
        }
      ],
      sortBy: 'redeem',
      resultsCopy: [],
      sortedResults: [],
      selectedRow: null,
      results: [],
      numberPages: 1,
      currentPage: 1,
      isOpened: false,
      selectedImage: '',
      infos: {
        banners: {
          title: 'Banners',
          text: 'São os banners cadastrados por você no painel do gestor.'
        },
        notifications: {
          title: 'Notificações',
          text: 'São as notificações cadastradas por você no painel do gestor.'
        }
      }
    }
  },
  created () {
    this.results = JSON.parse(JSON.stringify(this.tableData.data[this.table.prop]))
    this.buildResults()
    this.createPagination()
  },
  methods: {
    buildResults () {
      this.results = this.results.map(result => {
        return {
          ...result,
          campaign: result.campaign && result.campaign.name ? result.campaign.name : ' - ',
          resource: result.resource && result.resource.name ? result.resource.name : ' - ',
          start_date: new Date(result.start_date).toLocaleString('pt-BR', {
            timeZone: 'UTC',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }),
          end_date: new Date(result.end_date).toLocaleString('pt-BR', {
            timeZone: 'UTC',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        }
      })
    },
    sortResults (key) {
      const results = [...this.results]
      const sorted = results.sort((a, b) => (a[key] < b[key]) ? 1 : -1)
      this.resultsCopy = sorted
      this.firstPage()
    },
    changeSortKey (key) {
      this.sortBy = key
      this.sortResults(key)
    },
    createPagination () {
      if (this.results.length > 5) {
        this.numberPages = Math.ceil((this.results.length / 5))
      }
      this.resultsCopy = [...this.results]
      this.sortedResults = this.results.slice(0, 5)
    },
    nextPage () {
      const init = this.currentPage * 5
      const endPage = (init + 5)
      const end = endPage > this.resultsCopy.length ? this.resultsCopy.length : endPage
      this.sortedResults = this.resultsCopy.slice(init, end)
      this.currentPage = this.currentPage + 1
    },
    previousPage () {
      this.currentPage = this.currentPage - 1
      const init = (this.currentPage * 5) - 5
      const end = (init + 5)
      this.sortedResults = this.resultsCopy.slice(init, end)
    },
    lastPage () {
      this.currentPage = this.numberPages
      const end = this.resultsCopy.length
      const init = (this.currentPage * 5) - 5
      this.currentPage = this.numberPages
      this.sortedResults = this.resultsCopy.slice(init, end)
    },
    firstPage () {
      this.currentPage = 1
      this.sortedResults = this.resultsCopy.slice(0, 5)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
  .container {
  background: #FDFDFD;
  width: 100%;
  background: white;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  .main-table .not-available {
    margin-top: 48px;
  }
    .fake-pagination {
      position: relative;
      display: flex;
      justify-content: flex-end;
      bottom: unset;
      right: unset;
    }
    .title-container {
    display: flex;
    align-items: center;
    .title {
      font-size: 18px;
      color: $base-color;
      display: flex;
      align-items: center;
      margin-right: 8px;
      text-transform: none;
      .icon-title {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }
    .title, .table > .head > .head-item {
      color: #5E6684;
      text-transform: uppercase;
      font-family: $base-font-family;
  }
  .title {
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    color: $text-color;
  }
    .table {
      border-bottom: 1px solid $another-color;
      margin-top: 48px;

      .head {
        border-bottom: 1px solid $another-color;
      }
      .head th{
        text-align: inherit;
        padding-left: 18px;
      }
      .loading-page {
        max-width: 100%;
      }
      .head-item {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 10px;
        padding-right: 8px;
        text-align: center;
        vertical-align: bottom;
        cursor: pointer;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          padding-right: 0px;
        }
        .icon {
          color: #00B2E3;
          font-size: 20px;
          display: block;
          margin: 0 auto;
        }
      }

      .row {
        font-size: 12px;
        color: $title-color;
        transition: all ease 0.3s;
        &:hover {
          background: #D8E0F6;
        }
        &:focus {
          background: #202950;
          color: white;
        }
        .column:nth-child(3) {
          width:158px
        }
        .column:last-child {
          text-align: center;
        }
        .column {
          padding: 14px 8px 10px 8px;
          height: 80px;
          padding-left: 18px;
          .link {
            text-decoration: underline;
              color: #608CEA;
            }

          &:first-child {
            text-align: left;
          }
          .image-container {
            overflow: hidden;
            height: 48px;
            width: 124px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            & > .image {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: left;
            }
            & > .image:hover {
              filter: blur(5px);
            }
            & >.overlay-button {
                position: absolute;
                background: none;
                border: none;
                color: transparent;
                cursor: pointer;
                transition: color 0.3s;
                font-family: Nunito Sans;
                font-size: 14px;
                font-weight: 700;
                line-height: 19.1px;
                text-align: left;
                height: 100%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              }
              & >.overlay-button:hover {
                color: white;
                backdrop-filter: blur(5px);
              }

            & > .banner-preview .content img{
              display: flex;
              padding-top: 15px;
              width: 100%;

              min-height: 195px;
              justify-content: center;
              object-fit: cover;
              object-position: left;
            }

          }
        }
        .first, .last {
          padding: 16px 0;
        }
        .last {
          text-align: right;
          font-weight: 600;
        }
      }
      .row + .row {
        border-top: 1px solid $another-color;
      }
    }
  }

</style>
