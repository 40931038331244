<template>
  <div class="banners-table">
    <div class="cards">
      <communication-resume
        :key="loading"
        :loading="loading"
        title=""
        icon=""
        :card-data="bannersData"
        :graph-data="bannersChartData"
        :active-tab="'dashboard-communication-tab'"
      />
    </div>
    <div
      class="container"
      v-for="(table, index) in tables"
      :key="index"
    >
      <communication-tables
        :key="loading"
        :table-data="tableData"
        :table="table"
        :loading="loading"
        :success="tableData.resume.success"
      />
    </div>
  </div>
</template>

<script>

import CommunicationResume from './communication/CommunicationResume.vue'
import CommunicationTables from './communication/CommunicationTables.vue'
export default {
  name: 'BannersTable',
  components: {
    CommunicationTables,
    CommunicationResume
  },
  props: {
    tableData: Object,
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      tables: [
        {
          title: 'Banners Home',
          prop: 'banners'
        },
        {
          title: 'Notificações',
          prop: 'notifications'
        }
      ]
    }
  },
  computed: {
    bannersData () {
      return [
        {
          value: this.tableData.resume.banners_clicks,
          label: 'Cliques em banners'
        },
        {
          value: this.tableData.resume.notifications_clicks,
          label: 'Cliques em notificações'
        }
      ]
    },
    bannersChartData () {
      return {
        title: 'Conversão de notificações',
        infos: [
          {
            title: 'Cliques em banners',
            text: 'Quantidade de cliques em seus banners no período.'
          },
          {
            title: 'Cliques em notificações',
            text: 'Quantidade de cliques em suas notificações no período.'
          }
        ],
        gauge: {
          title: 'Conversão de notificações',
          description: 'É a relação entre impressões e cliques nas suas notificações'
        },
        columns: [this.tableData.resume.notifications_conversion_rate]
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.banners-table {
    display: flex;
    flex-direction: column;
    gap: 32px;
  .cards {
    display: flex;
  }
  .cards div:last-child {
    margin-right: 0;
    display: flex;
    justify-content: space-around
  }
  .cards div div {
    margin-left: 20px;
  }
  .container {
    background: #FDFDFD;
    width: 100%;
    background: white;
    padding: 20px 16px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  }
}
</style>
