<template>
  <div
    class="graph-card"
    :class="horizontal ? '-horizontal' : ''"
  >
    <h4 class="title">
      {{ graphData.title }}
      <info-button
        icon="info-information-circle"
        class="title-icon"
        :info="graphData.info || null"
      />
    </h4>
    <div
      v-if="tabs.length > 0"
      class="tabs"
    >
      <h3
        v-for="(tab, index) in tabs"
        :key="tab"
        class="tab"
        :class="selectedTab === index ? '-selected' : ''"
        @click="selectTab(index)"
      >
        {{ tab }}
      </h3>
    </div>
    <div class="inner">
      <loading-card v-if="loading" />
      <not-available
        v-else-if="error || isDataEmpty"
        :type="error ? 'error' : 'empty'"
      />
      <donut-graphic
        v-else
        class="graph"
        :graph-data="correctDonutData"
      />
    </div>
  </div>
</template>

<script>
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'
import NotAvailable from '@/components/Dashboard/base/notAvailable.vue'
import LoadingCard from '@/components/Dashboard/loading/LoadingCard.vue'
import DonutGraphic from '@/components/Dashboard/base/DonutGraphic.vue'

export default {
  name: 'GraphCard',
  components: {
    InfoButton,
    LoadingCard,
    DonutGraphic,
    NotAvailable
  },
  props: {
    loading: Boolean,
    error: Boolean,
    horizontal: Boolean,
    graphData: Object,
    tabs: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      selectedTab: 0
    }
  },
  computed: {
    isDataEmpty () {
      if (typeof this.graphData.columns === 'undefined') {
        return true
      } else if (Array.isArray(this.graphData.columns)) {
        const isEmpty = this.graphData.columns.findIndex(value => parseInt(value) !== 0)
        return isEmpty === -1
      } else {
        const values = Object.values(this.graphData.columns)
        const isEmpty = values.findIndex(value => parseInt(value) !== 0)
        return isEmpty === -1
      }
    },
    correctDonutData () {
      if (this.tabs.length === 0) return this.graphData
      const formattedDonutData = {
        ...this.graphData,
        columns: this.graphData.columns[this.selectedTab]
      }

      return formattedDonutData
    }
  },
  methods: {
    selectTab (index) {
      this.selectedTab = index
      this.$emit('select-tab', index)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.graph-card {
  max-width: 334px;
  height: 421px;
  padding: 30px 0 20px 0;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  @include mobile {
    max-width: 100%;
    margin-top: 20px;
  }

  .title {
    font-family: $base-font-family;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    color: $text-color;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title-icon {
      margin-left: 5px;
    }
  }

  .title, .tab {
    color: $text-color;
    text-transform: uppercase;
    font-family: $base-font-family;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
  }

  > .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #BEC0D3;
    margin-bottom: 40px;

    > .tab {
      padding: 10px;
      margin: 0 5px;
      cursor: pointer;

      &.-selected {
        color: #202950;
        border-bottom: 2px solid #202950;
      }
    }
  }

  .inner {
    height: 254px;

    > .loading-card {
      width: 100%;
    }
  }

  &.-horizontal {
    width: 100%;
    max-width: 650px;
    padding-top: 20px;

    .inner {
      > .graph {
        display: grid;
        grid-template-columns: 1fr 2fr;

        :deep .circle {
          left: 105px;
        }

        :deep .legends > .graphic-legend {
          display: grid;
          grid-template-columns: 1fr 3fr;
          grid-template-rows: repeat(2, 1fr);

          > .color {
            grid-row: 1 / -1;
          }
        }
      }
    }
  }
}
</style>
