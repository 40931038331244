<template>
  <div
    class="dashboard-users-tab"
  >
    <div class="donuts-cards">
      <banners-table
        :loading="loading"
        :table-data="tableData"
        :selectedfilters="['search']"
      />
    </div>
  </div>
</template>

<script>
import BannersTable from '@/components/Dashboard/BannersTable.vue'
import { mapGetters } from 'vuex'
import { DASH_COMMUNICATION_RESUME } from '@/store/types'
export default {
  name: 'DashboardCommunicationTab',
  components: {
    BannersTable

  },
  props: {
    underConstruction: Boolean,
    tabName: String,
    loading: Boolean
  },

  data () {
    return {
      labels: {
        banners: [
          {
            name: 'Imagem',
            column: 'image',
            isImage: true
          },
          {
            name: 'Nome',
            column: 'name'
          },
          {
            name: 'Oferta',
            column: 'campaign'
          },
          {
            name: 'Data inicial',
            column: 'start_date'
          },
          {
            name: 'Data final',
            column: 'end_date'
          },
          {
            name: 'Cliques',
            column: 'clicks'
          }
        ],
        notifications: [
          {
            name: 'Imagem',
            column: 'image',
            isImage: true
          },
          {
            name: 'Conteúdo',
            column: 'name'
          },
          {
            name: 'Oferta',
            column: 'campaign'
          },
          {
            name: 'Data inicial',
            column: 'start_date'
          },
          {
            name: 'Data final',
            column: 'end_date'
          },
          {
            name: 'impressões',
            column: 'opens'
          },
          {
            name: 'Cliques',
            column: 'reads'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      communicationResumeData: DASH_COMMUNICATION_RESUME
    }),

    tableData () {
      return {
        resume: this.communicationResumeData,
        data: {
          banners: this.communicationResumeData.banners,
          notifications: this.communicationResumeData.notifications
        },
        labels: this.labels,
        slot: true,
        slotTable: 1,
        tabs: []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

.dashboard-users-tab {
  width: 100%;
  .donuts-cards {
    display: grid;
    max-width: 100%;
    margin-top: 20px;
    .graph-card .registration-info-card {
      position: relative;
      z-index: 3;
      background: #FDFDFD;
      padding: 16px;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 6px 6px;
      > .graph-card {
        width: 100%;
        min-width: 290px;
        box-shadow: none;
      }
    }
  }
}

</style>
